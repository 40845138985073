.h2{
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    color: #333740;
}
.h3{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #5F6369;
}
.text{
    display: flex;
    gap: 17px;
    flex-direction: column;
    text-align: center;
    width: 25%;
}
@media(max-width:1000px){
    .text{
        width: 90%;
    }
    
}