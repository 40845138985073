.back {
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 4;
}

.con {
    display: flex;
    width: 90%;
    height: 90%;
    background: #fff;
    position: relative;
}

.imgCon {
    width: 100%;
}

.img {
    width: 90%;
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 39px;
    line-height: 46px;
    text-transform: uppercase;
    color: #333740;
}

.pActive {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    position: relative;
    color: #333740;
    transition: 0.3s;
    cursor: pointer;
}

.pActive::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #004A94;
    transition: 0.3s;
}

.p {
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 32px;
    transition: 0.3s;
    color: #717D72;
    cursor: pointer;
}

.check {
    display: flex;
    cursor: pointer;
    gap: 15px;
}

.сharacteristics {
    height: 50%;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.info {
    height: 90%;
    align-items: center;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.exit {
    position: absolute;
    top: 15px;
    right: 50px;
}
.pCall{
    cursor: pointer;
}
.call {
    position: absolute;
    bottom: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 32px;
    color: #333740;
}

@media(max-width:1200px) {
    .h2 {
        font-size: 25px;
    }

    .p {
        font-size: 23px;
    }

    .pActive {
        font-size: 24px;
    }
    .pCall{
        font-size: 20px;
    }
}

@media(max-width:1000px) {
    .h2 {
        font-size: 25px;
    }

    .p {
        font-size: 15px;
    }

    .pActive {
        font-size: 16px;
    }
    .pCall{
        font-size: 18px;
    }
}

@media(max-width:690px) {
    .con {
        height: 100%;
        width: 100%;
    }
    .сharacteristics{
        align-items: center;
    }
    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .h2 {
        font-size: 23px;
    }

    .p {
        font-size: 13px;
    }

    .img {
        width: 100%;
    }

    .pActive {
        font-size: 14px;
    }

    .exitImg {
        width: 80%;
    }

    .exit {
        right: 25px;
    }
    .pCall{
        font-size: 15px;
    }
}

@media(max-width:520px){
    .h2{
        font-size: 12px;
    }
    .call{
        font-size: 15px;
    }
    .callSvg{
        width: 5%;
        display: none;
    }
    .exit{
        right: 5px;
    }
    .exitImg{
        width: 60%;
    }
    .pCall{
        font-size: 11px;
    }
}