.footer{
    display: flex;
    width: 100%;
    height: 170.31px;
    justify-content: center;
    border-top: 4px solid #D9D9D9;
    align-items: center;
}
.con{
    width: 90%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #5F6369;
}
.logo{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.img{
    width: 278.87px;
}
.links{
    display: flex;
    gap: 15px;
    align-items: center;
}
.logo_con{
    display: flex;
    height: 80%;
    gap: 15px;
}
.con_logo{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5px;
    justify-content: center;
}
@media(max-width:650px){
    .img{
        width: 208.87px;
    }
    .p{
        font-size: 15px;
    }
    .footer{
        height: 140px;
    }
    .link{
        width: 45px;
    }
}
@media(max-width:500px){
  .links{
    flex-direction: column;
    gap: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .link{
    width: 25px;
}
}