.nav {
    width: 100%;
    height: 95px;
    background: #FFFFFF;
    border-bottom: 2px solid #004A94;
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 2;
}

.con {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons {
    display: flex;
    gap: 25px;
    align-items: center;
}

.logo {
    width: 257px;
    height: 55px;
}

.p {
    display: flex;
    align-items: center;
    justify-content: center;
}
.img{
    width: 40px;
}


@media(max-width:780px) {
    .logo {
        width: 155px;
        height: 37px;
    }
}

@media(max-width:700px) {
    .buttons {
        gap: 5px;
    }

    .con {
        width: 95%;
    }
}
