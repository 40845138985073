.h2{
    font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 27px;
color: #333740;
}
.text{
    width: 90%;
    height: 100%;
}
.li{
    font-style: normal;
font-weight: 500;
font-size: 23px;
line-height: 27px;
color: #333740;
}
@media(max-width:1200px){
    .h2{
        font-size: 20px;
    }
    .li{
        font-size: 20px;
    }
}
@media(max-width:1000px){
    .h2{
        font-size: 15px;
    }
    .li{
        font-size: 15px;
    }
}
@media(max-width:690px){
    .h2{
        font-size: 13px;
    }
    .li{
        font-size: 13px;
    }
 
}