.con {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 15px rgba(51, 55, 64, 0.25));
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    background: #F0F0F0;
    border: 1px solid #333740;
    transition: 0.3s;
}


.img {
    width: 100%;
}

.h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    padding: 0 15px;
    color: #333740;
}