.takeit {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: #D9D9D9 solid 4px;
    height: 100vh;
    padding: 50px 0;

}

.con {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #000000;
    margin: 0 0 80px 0;
}

.img {
    width: 100%;
    height: 100%;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    justify-content: center;
}

.info {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    justify-content: space-between;
    gap: 25px;
}

.text_h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    width: 100%;
    color: #000000;
}

.h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    width: 100%;
    color: #5F6369;
}

@media(max-width:1370px) {
    .text_h2 {
        font-size: 31px;
        line-height: 34px;
    }

    .h3 {
        font-size: 20px;
    }
}

@media(max-width:1000px) {
    .imgCon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info {
        grid-template-columns: 1fr;
        gap: 25px;
    }

    .img {
        width: 70vh;
    }
    .text{
    text-align: center;

    }
}

@media(max-width:670px) {
    .imgCon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .takeit{
        height: 120vh;
    }
    .info {
        display: flex;
        flex-direction: column;
    }

    .img {
        width: 80vw;
    }

    .text_h2 {
        font-size: 28px;
    }

    .h3 {
        font-size: 16px;
        line-height: 23px;
    }
}