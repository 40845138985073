.product{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: #D9D9D9 solid 4px;
    padding: 50px 0;
}
.con{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h2{
    font-style: normal;
font-weight: 700;
font-size: 50px;
line-height: 61px;
color: #000000;
}
.products{
    margin-top: 50px;
    display: grid;
    grid-template-columns:repeat(5, 1fr) ;
    grid-template-rows: repeat(6, 285px) auto;
    width: 100%;
    height: 100%;
    gap: 15px;
}


@media(max-width:1250px){
   .products{
    grid-template-columns:repeat(4, 1fr) ;
   } 
}
@media(max-width:1000px){
    .products{
     grid-template-columns:repeat(3, 1fr) ;
    } 
 }
@media(max-width:650px){
    .products{
     grid-template-columns:repeat(2, 1fr) ;
    } 
 }
 @media(max-width:480px){
    .products{
     grid-template-columns:repeat(1, 1fr) ;
    } 
 }