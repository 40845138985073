.first{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 96px;
    border-bottom: #D9D9D9 solid 4px;
}
.con{
    display: flex;
    width: 90%;
    height: 100%;
    flex-direction: column;
    margin-bottom: 96px;
}
.info{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.info_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    height: 100%;
}
.h1{
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    color: #333740;
}
.h2{
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    width: 90%;
    color: #5F6369;
}
.img{
    width: 42vw;
}
.text{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 96px;
}
@media(max-width:1400px){
  .h1{
    font-size: 31px;
    line-height: 34px;
  }
  .h2{
    font-size: 20px;
    line-height: 25px;
  }
}
@media(max-width:1180px){
    .h1{
      font-size: 25px;
      line-height: 30px;
    }
    .h2{
      font-size: 16px;
      line-height: 23px;
    }
  }
  @media(max-width:1000px){
   
    .info{
        flex-direction: column;
        gap: 50px;
    }
    .img{
        width: 100%;
    }
    .text{
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
    .h1{
        text-align: center;
        font-size: 28px;
    }
    .h2{
        font-size: 16px;
        line-height: 23px;
        width: 100%;
      }
}
@media(max-width:460px){
    .con{
        width: 95%;
    }
}
