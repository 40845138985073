.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 75px;
    height: 75px;
}

.con {
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #000000;
    text-transform: uppercase;
}
@media(max-width:680px){
    .btn{
        width: 50px;
        height: 50px;
    }
    .con {
        font-weight: 700;
        font-size: 10px;
    }
}