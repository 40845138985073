.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.con {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.h2{
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #000000;
}
.info{
    display: grid;
    grid-template-columns: repeat(5,250px);
    grid-template-rows: repeat(2,250px);
    gap: 50px;
}
.adidas{
    width: 200px;
}
.img{
    width: 250px;
}
.nike{
    width: 187px;
}
.a{
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width:1600px){
    .info{
        grid-template-columns: repeat(4,250px);
    grid-template-rows: repeat(3,250px);
    gap: 45px;
    }
}
@media(max-width:1300px){
    .info{
        grid-template-columns: repeat(3,250px);
    grid-template-rows: repeat(4,250px);
    gap: 35px;
    }
}
@media(max-width:1000px){
    .info{
        grid-template-columns: repeat(2,250px);
    grid-template-rows: repeat(5,250px);
    gap: 25px;
    }
    .img{
        width: 200px;
    }
    .adidas{
        width: 150px;
    }
    .nike{
        width: 137px;
    }
}
@media(max-width:600px){
    .info{
        grid-template-columns: repeat(1,250px);
    grid-template-rows: repeat(10,250px);
    gap: 15px;
    }
    .img{
        width: 200px;
    }
    .adidas{
        width: 150px;
    }
    .nike{
        width: 137px;
    }
}