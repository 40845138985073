* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Rubik', sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #004A94;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00647d;
}
@media(max-width:1000px){
  *{
    cursor: default;
  }
}