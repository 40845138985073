.main {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 70vh;
    margin-top: 95px;
}

.con {
    width: 90%;
    display: flex;
    align-items: center;
    background: url('../Images/bg.svg') no-repeat;
    height: 100%;
    position: relative;
}

.text {
    position: absolute;
    left: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.h2 {
    display: flex;
    padding: 25px 224px 25px 18px;
    background: rgba(100, 98, 99, 0.75);
    font-style: normal;
    font-weight: 700;
    font-size: 41px;
    line-height: 50px;
    color: #FFFFFF;
}

.h3 {
    display: flex;
    padding: 25px 18px;
    width: 460px;
    background: rgba(100, 98, 99, 0.75);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

@media(max-width:900px) {
    .h2 {
        padding: 15px 124px 15px 18px;
        font-size: 35px;
    }

    .h3 {
        padding: 15px 18px;
        width: 371px;
        font-size: 17px;
    }
}
@media(max-width:700px) {
    .con{
        justify-content: center;
    }
    .text{
        position: relative;
        left: 0;

    }
    .h2 {
        padding: 15px 30px 15px 18px;
        font-size: 29px;
    }

    .h3 {
        padding: 15px 18px;
        width: 275px;
        font-size: 17px;
    }
}
@media(max-width:500px) {
    .h2 {
        font-size: 25px;
    }
}
@media(max-width:400px) {
    .h2 {
        font-size: 23px;
    }
}
@media(max-width:460px){
   
    .con{
        width: 95%;
    }
}
@media(min-height:370px ) and (max-height:500px){
    .main{
        height: 108vh;
    }
}